<template>
  <a-modal
    v-model="visible"
    :mask-closable="false"
    :title="treeTitle"
    :footer="null"
    :z-index="9999"
    @cancel="closeVisibleTree"
  >
    <div class="department_tree">
      <!--   左侧tree   -->
      <div class="department_tree_father department_tree_father_one">
        <!--   搜索框   -->
        <div class="search_class">
          <Search ref="searchValueRef" v-model="searchValue" :placeholder="placeholder" @focus="focus" @getSearchValue="getSearchValueData" @clearBread="clearBread" @visibleCallback="visibleCallback" />
        </div>
        <!--   搜索结果页   -->
        <div v-if="searchResultVisible" class="search_result">
          <SearchResult :search-result-list="searchResultListData" :menu-list-data="menuListData" @getSearchResultData="getSearchResultData" />
        </div>
        <div v-else>
          <!--   面包屑   -->
          <div class="crumbs_class">
            <Breadcrumb :breadcurmb-list="breadcurmbList" :cor-name="corName" @getBreadcurmb="getBreadcurmb" />
          </div>
          <!--   左侧tree   -->
          <div class="tree_class">
            <Tree
              ref="tree"
              :tree-data-dep-peo="treeDataDepPeoList"
              :menu-list-data="menuListData"
              :per-data-list="perDataLists"
              :initial-tree="initialTree"
              @getMenuTreeData="getMenuTreeData"
              @getDataSource="getDataSource"
              @getBreadcurmbData="getBreadcurmbData"
            />
          </div>
        </div>
      </div>
      <!--   右侧菜单   -->
      <div class="department_tree_father">
        <DataList
          :menu-list-data="menuListData"
          :dep-list-data="depListData"
          :pro-list-data="proListData"
          @delCallBack="delCallBack"
          @delAllCallBack="delAllCallBack"
          @closeModel="closeVisibleTree"
          @perModelConfirm="perModelConfirm"
        />
      </div>
    </div>
  </a-modal>
</template>

<script>
import Search from './sarch/index.vue'
import SearchResult from './searchResult/index.vue'
import Breadcrumb from './breadcrumb/index.vue'
import Tree from './tree/index.vue'
import DataList from './dataList/index.vue'
import { getOrgTree, getOrgStaff, getStaffVague } from '@/services/dropDownInput/index.js'
export default {
  name: 'PerDepModel',
  components: {
    Search,
    SearchResult,
    Breadcrumb,
    Tree,
    DataList
  },
  model: {
    prop: 'modelVisible',
    event: 'getModelVisible'
  },
  props: {
    treeTitle: {
      type: String,
      default: '人员选择'
    },
    modelVisible: Boolean,
    searchResultList: {
      type: Array,
      default: () => {}
    },
    treeDataDepPeo: {
      type: Array,
      default: () => []
    },
    placeholder: {
      type: String,
      default: '请输入部门、人员、工号'
    },
    perDataList: {
      type: Array,
      default: () => []
    },
    checkEcho: {
      type: Array,
      default: () => []
    },
    getPerDataList: {
      type: Function,
      default: null
    },
    searchValueList: {
      type: Function,
      default: null
    },
    // 判断是否请求 已离职 人员
    queryLeavePerson: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      visible: false,
      initialTree: '0',
      treeDataDepPeoList: [],
      perDataLists: [],
      corName: '',
      orgId: '', // 组织id
      searchValue: '', // 搜索框绑定
      searchResultVisible: false, // 搜索页展示
      searchResultListData: [],
      menuListData: [], // 菜单展示列表
      depListData: [], // 部门列表
      proListData: [], // 人员列表
      breadcurmbList: [] // 面包屑数据
    }
  },
  watch: {
    modelVisible(val) {
      this.visible = val
    },
    visible(newval) {
      if (newval) {
        this.initialTree = '0'
      }
      this.delAllCallBack()
      const val = JSON.parse(JSON.stringify(this.checkEcho))
      this.menuListData = val
      for (let i = 0; i < val.length; i++) {
        if (val[i].identification === 'dep') {
          this.depListData.push(val[i])
        } else {
          this.proListData.push(val[i])
        }
      }
    }
  },
  mounted() {
    this.getOrgTree()
  },
  methods: {
    // 获取数据部门
    async getOrgTree() {
      if (this.treeDataDepPeo && this.treeDataDepPeo.length > 0) {
        this.corName = this.treeDataDepPeo[0].orgName
        this.treeDataDepPeoList = this.orgTreeHandel(this.treeDataDepPeo[0].children)
      } else {
        const res = await getOrgTree({ nested: true, staffNum: true, leaveStaff: this.queryLeavePerson })
        this.corName = res.data[0].orgName
        this.treeDataDepPeoList = this.orgTreeHandel(res.data[0].children)
      }
    },
    // 处理接口返回值部门
    orgTreeHandel(data) {
      data.forEach(item => {
        item['title'] = item.orgName
        item['id'] = item.orgId
        item['numPeo'] = item.staffCount
        if (item.children && item.children.length > 0) {
          this.orgTreeHandel(item.children)
        }
      })
      return data
    },
    // 获取数据人员
    async getOrgStaff() {
      const res = await getOrgStaff({ orgId: this.orgId, leaveStaff: this.queryLeavePerson })
      return res.data
    },
    // 搜索
    async getSearchValueData(val) {
      const data = await this.searchValueList ? this.searchValueList(val) : this.getStaffVague()
      if (val) {
        const res = await data
        this.searchResultListData = res ? res.map(item => {
          return {
            ...item,
            title: item.staffName,
            id: item.onJobId,
            jobNumber: item.staffNum
          }
        }) : []
      } else {
        this.searchResultListData = []
      }
    },
    // 获取搜索结果数据
    async getStaffVague() {
      const res = await getStaffVague({ inputValue: this.searchValue, leaveStaff: this.queryLeavePerson })
      return res.data
    },
    focus() {
      this.searchResultVisible = true
    },
    visibleCallback() {
      // this.breadcurmbList = []
      // this.treeDataDepPeoList = this.breadcurmbList[this.breadcurmbList.length - 1].children
      this.searchResultVisible = false
      this.searchResultListData = []
    },
    clearBread() {
      this.breadcurmbList = []
    },
    // 搜索页关联菜单
    getSearchResultData(data, checked) {
      if (checked) {
        this.menuListData.push(data)
        if (data.identification === 'dep') {
          this.depListData.push(data)
        } else {
          this.proListData.push(data)
        }
      } else {
        this.menuListData = this.menuListData.filter(item => item.id !== data.id)
        if (data.identification === 'dep') {
          this.depListData = this.depListData.filter(item => item.id !== data.id)
        } else {
          this.proListData = this.proListData.filter(item => item.id !== data.id)
        }
      }
    },
    // 面包屑
    getBreadcurmbData(data) {
      this.breadcurmbList.push(data)
    },
    // 面包屑回调切换
    getBreadcurmb(item) {
      if (item.id === '0') {
        this.$refs.tree.getBreadcurmb(item)
        this.breadcurmbList = []
      } else {
        const breadcurmbList = this.breadcurmbList
        for (let i = 0; i < breadcurmbList.length; i++) {
          if (breadcurmbList[i].id === item.id) {
            breadcurmbList.splice(i + 1, breadcurmbList.length - (i + 1))
            this.$refs.tree.getBreadcurmb(item)
          }
        }
      }
    },
    // 点击checkbox回调
    getMenuTreeData(data) {
      if (data.disable) {
        this.menuListData.push(data)
        if (data.identification === 'dep') {
          this.depListData.push(data)
        } else {
          this.proListData.push(data)
        }
      } else {
        this.menuListData = this.menuListData.filter(item => item.id !== data.id)
        if (data.identification === 'dep') {
          this.depListData = this.depListData.filter(item => item.id !== data.id)
        } else {
          this.proListData = this.proListData.filter(item => item.id !== data.id)
        }
      }
    },
    // 点击获取数据
    async getDataSource(item) {
      this.orgId = item.id
      const data = await this.getPerDataList ? this.getPerDataList(item.id) : this.getOrgStaff()
      const res = await data
      const tempData = res.map(item => {
        return {
          ...item,
          title: item.staffName,
          id: item.onJobId,
          jobNumber: item.staffNum
        }
      })
      this.$set(this, 'perDataLists', tempData)
    },
    // 菜单列删除
    delCallBack(id) {
      this.menuListData = this.menuListData.filter(item => item.id !== id)
      this.depListData = this.depListData.filter(item => item.id !== id)
      this.proListData = this.proListData.filter(item => item.id !== id)
    },
    // 删除全部
    delAllCallBack() {
      this.menuListData = []
      this.depListData = []
      this.proListData = []
    },
    perModelConfirm() {
      this.$refs.searchValueRef.visibleCallback()
      this.$emit('perModelConfirm', this.menuListData)
    },
    // 关闭弹窗
    closeVisibleTree() {
      this.visible = false
      this.$refs.searchValueRef.visibleCallback()
      this.$emit('getModelVisible', this.visible)
    },
    // 清空
    clear() {
      this.breadcurmbList = []
      this.visibleCallback()
      this.delAllCallBack()
      this.initialTree = '1'
    }
  }
}
</script>

<style lang="less" scoped>
  .department_tree {
    display: flex;
    flex-direction: row;
    .department_tree_father_one {
      border-right: 1px rgba(143, 147, 161, 0.30000001192092896) solid;
    }
    .department_tree_father {
      width: 50%;
      height: 566px;
      padding: 18px 24px;
      box-sizing: border-box;
      .search_class {
        width: 100%;
      }
      .search_result {
        overflow: auto;
        width: 100%;
        height: 492px;
        margin-top: 16px;
      }
      .crumbs_class {
        width: 100%;
        font-size: 14px;
        color: #202A40;
        margin-top: 14px;
      }
      .tree_class {
        overflow: auto;
        width: 100%;
        height: 450px;
        margin-top: 5px;
      }
    }
  }
  /deep/ .ant-modal {
    width: 915px !important;
  }
  /deep/ .ant-modal-body {
    height: 566px;
    margin: 0;
    padding: 0;
  }
</style>
