<template>
  <div>
    <!--  顶部  -->
    <div class="department_top">
      <div>
        <span>已选：</span>
        <span class="department_top_margin">部门{{ depListData ? depListData.length : 0 }}个</span>
        <span class="department_top_margin">人员{{ proListData ? proListData.length : 0 }}个</span>
      </div>
      <div class="clear_all" @click="delAllCallBack">全部清空</div>
    </div>
    <!--  列表  -->
    <div class="department_count">
      <div v-for="(item) in menuListData" :key="item.id" class="department_count_data">
        <!--  部门  -->
        <div v-if="item.identification === 'dep'" class="department_count_datas">
          <div>
            <img :src="imgBase" alt="" class="icon">
            <span class="title">{{ item.title }}</span>
            <!--            <span>({{ item.numPeo }}人)</span>-->
          </div>
          <a-icon type="close-circle" theme="filled" class="filled_icon" @click="delCallBack(item.id)" />
        </div>
        <!--  人员  -->
        <div v-else class="department_count_datas">
          <div class="department_count_info">
            <CbAvater
              :on-job-id="item.onJobId"
              :avater-name="item.staffName"
              size="30"
            />
            <span class="jobNumber">{{ item.phoneNum }}</span>
          </div>
          <a-icon type="close-circle" theme="filled" class="filled_icon" @click="delCallBack(item.id)" />
        </div>
      </div>
    </div>
    <!--  按钮  -->
    <div class="button">
      <a-button class="button_close" @click="closeModel">取消</a-button>
      <a-button type="primary" @click="perModelConfirm">确认</a-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DataList',
  components: {
    CbAvater: () => import('@/components/CbAvater/theAvater.vue')
  },
  props: {
    menuListData: {
      type: Array,
      default: () => {}
    },
    depListData: {
      type: Array,
      default: () => {}
    },
    proListData: {
      type: Array,
      default: () => {}
    }
  },
  data() {
    return {
      imgBase: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADYAAAAqCAYAAAD4Uag9AAAABHNCSVQICAgIfAhkiAAAA/tJREFUaIHdmj1QG3cQxX/vxJdHzJg0uAsybWDGpVxFGddRzinThBSp7RKTwkpD6AJtKIwb0hnRhzHu7CpkYCZV4o/SRRx7IALju5fikDkSkHSIjzOv0q32Tu/p9r+7/70Th+DmncanNpWAuGSpdJjPWcGwhuL79enBtSznKX0QTjYqkheAkdSFnwptnQzNbBBcNS7uHT43vl3/oVjv8NwEX05t1WzdFdqK0bwUrOPg5akwzgLFw8TRdXAVMSx74cFM8Zu2p8H7O/XQ8CRw348W53KHWkGmaO1+C77RibgAQPKC0FZeRQEkvHpnQSuWJsKpxkQr/yC8sxUCIzGaz6uog+idxbyUXWvlFQTiGoAUrJ8Jr5OAtAKMhJON0lEuPTbJl5kSxW4VKHdFrhvYV/bSXgl4dphLD6gE2uj8qrvT4HHDC8HTLikeD+Iv4HeC6O+jXHqyXXH3K/C45O+Xpou17tidLoKM/p8Djx7kXBRkEaaoDB60tHB6dE4OnQtznCSLvu2OWprzRoZQ9BjwW7320ZELNk/oMHm8GwWuCGbS1nCyUZL2G+bzgPu3D/2zO82KNwBi60AYSr4HVLpm1wW008/NO/88t3WPge25psgOhXkMeF6fufSsaUmqviuGJ7iw3PLHA4o4Hj02+3bscFlyjbf9t8Opzc/q04Nr7YUpHsYexcwdMOPbAFLPT9CmazFA4fHxqbehCIt2NA7Rd0KrYe1VqX3yiKPrCbf9MAxrr4aQJ0AbudizAVJhXSrMgi7zdiBsL0yUjd/UZy6tvre9HQhBl41+OUWu2eEkKoRbC5MpgscESwftvoV5KXpWTpPn8aANzFBLYbHelQEM+2E42agA1/a2DnlECdoW6Pg6QHqAIsUTyYdCvsIQkkSHByWvtg5FtAl7yYJmitfXoJW8JI007HgcII6D1sJQkCzGnf6lcLJxV/JD0KbcO38GPDNDkNTKge02dcyFxxD/bFyVXAH9gYI5k9fZyH4/20Hn0bsoWEzGX2wlxTav8Cj4PmTo7vM+wUo6D7CCVci+g84tpCRxQLQGmWce+YWTMHzTfHhxce4Y+kTo1+bxxRCWKsxN04UQli7MTduFECY8DpDegQQAxlfPidMJISnMaUuAWRMUkzj98LBXv64IFtL2wEGcGBx/cfa0uoeIqvD/QVNQnx5cQyxDXG1W7w8GisqIMmYuPWiC5hrr25kAvZaiqWSUnX/Y0TiObhleeGCn9t/v3z9cD6c2r+FgWfAxaF0Ey6bw5EzZdgLFwxBXk6Wj11ZUOexViYOvQ9ReDWm7v4a4lXL5k9xsU5xeKo/cvxMeNXLXYcZwslEK5NCighk6FY7HhOTVGNfbvdDyL6TXmYt7pBqKAAAAAElFTkSuQmCC',
      basePath: process.env.VUE_APP_API_BASE_URL
    }
  },
  methods: {
    delCallBack(id) {
      this.$emit('delCallBack', id)
    },
    delAllCallBack() {
      this.$emit('delAllCallBack')
    },
    closeModel() {
      this.$emit('closeModel')
    },
    perModelConfirm() {
      this.$emit('perModelConfirm')
    }
  }
}
</script>

<style lang="less" scoped>
.imgnone{
  display: none;
}
  .department_top {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    color: @sc-grey-100;
    margin-top: 5px;
    .department_top_margin {
      margin-left: 15px;
    }
    .clear_all{
      cursor: pointer;
      color: #F34856;
    }
  }
  .department_count {
    width: 100%;
    height: 466px;
    overflow: auto;
    margin-top: 5px;
    .department_count_data {
      margin: 11px 0;
      .department_count_datas {
        display: flex;
        justify-content: space-between;
        color: @sc-grey-100;
        font-size: 14px;
        margin: 0 0 26px 0;
        .department_count_info{
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          /deep/.box .user-name{
            margin-left: 10px;
          }
        }
        .icon {
          width: 26px;
          height: 20px;
          margin: 0 10px 0 0;
        }
        .filled_icon {
          color: @sc-greyBg-50;
          margin-top: 5px;
        }
        .title {
          margin-right: 8px;
        }
        .jobNumber {
          margin-right: 20px;
          width: 100px;
          color: @sc-grey-60;
        }
      }
    }
  }
  .button {
    text-align: right;
    .button_close {
      margin-right: 12px;
    }
    /deep/ .ant-btn {
      padding: 5px 10px;
      span {
        .font-size(14px);
      }
    }
  }
</style>
