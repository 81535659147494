<template>
  <div>
    <div v-if="treeData && treeData.length> 0" class="check_box">
      <a-checkbox-group v-model="list">
        <div v-for="(item) in treeData" :key="item.id">
          <div v-if="item.identification === 'dep'" class="margin_bottom">
            <a-checkbox :value="item.id" :disabled="item.disabled" @change.stop="onChange" />
            <span class="cursor peo_box" @click.stop="titleClick(item)">
              <img :src="imgBase" alt="" class="icon">
              <span :class="item.disabled || list.indexOf(item.id) !== -1 ? 'disabled_color' : ''">
                <span class="title" :title="item.title">{{ item.title }}</span>
                <!--                <span>({{ item.numPeo }}人)</span>-->
              </span>
            </span>
          </div>
          <div v-else class="margin_bottom">
            <a-checkbox :value="item.id" class="peo_per" @change="onChange">
              <span class="peo_box">
                <CbAvater
                  :on-job-id="item.onJobId"
                  :avater-name="item.staffName"
                  size="30"
                />
                <span class="jobNumber">{{ item.phoneNum }}</span>
              </span>
            </a-checkbox>
          </div>
        </div>
      </a-checkbox-group>
    </div>
    <div v-else class="perNoData_div">
      <CbNoData class="perNoData" />
    </div>
  </div>
</template>

<script>
import { getTree, getTreeChil, getChildren, getIdData, perfectTree } from './index.js'
export default {
  name: 'Tree',
  components: {
    CbAvater: () => import('@/components/CbAvater/theAvater.vue'),
    CbNoData: () => import('@/components/CbNoData/index')
  },
  props: {
    treeDataDepPeo: {
      type: Array,
      default: () => []
    },
    menuListData: {
      type: Array,
      default: () => {}
    },
    perDataList: {
      type: Array,
      default: () => []
    },
    initialTree: {
      type: String,
      default: '0'
    }
  },
  data() {
    return {
      imgBase: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADYAAAAqCAYAAAD4Uag9AAAABHNCSVQICAgIfAhkiAAAA/tJREFUaIHdmj1QG3cQxX/vxJdHzJg0uAsybWDGpVxFGddRzinThBSp7RKTwkpD6AJtKIwb0hnRhzHu7CpkYCZV4o/SRRx7IALju5fikDkSkHSIjzOv0q32Tu/p9r+7/70Th+DmncanNpWAuGSpdJjPWcGwhuL79enBtSznKX0QTjYqkheAkdSFnwptnQzNbBBcNS7uHT43vl3/oVjv8NwEX05t1WzdFdqK0bwUrOPg5akwzgLFw8TRdXAVMSx74cFM8Zu2p8H7O/XQ8CRw348W53KHWkGmaO1+C77RibgAQPKC0FZeRQEkvHpnQSuWJsKpxkQr/yC8sxUCIzGaz6uog+idxbyUXWvlFQTiGoAUrJ8Jr5OAtAKMhJON0lEuPTbJl5kSxW4VKHdFrhvYV/bSXgl4dphLD6gE2uj8qrvT4HHDC8HTLikeD+Iv4HeC6O+jXHqyXXH3K/C45O+Xpou17tidLoKM/p8Djx7kXBRkEaaoDB60tHB6dE4OnQtznCSLvu2OWprzRoZQ9BjwW7320ZELNk/oMHm8GwWuCGbS1nCyUZL2G+bzgPu3D/2zO82KNwBi60AYSr4HVLpm1wW008/NO/88t3WPge25psgOhXkMeF6fufSsaUmqviuGJ7iw3PLHA4o4Hj02+3bscFlyjbf9t8Opzc/q04Nr7YUpHsYexcwdMOPbAFLPT9CmazFA4fHxqbehCIt2NA7Rd0KrYe1VqX3yiKPrCbf9MAxrr4aQJ0AbudizAVJhXSrMgi7zdiBsL0yUjd/UZy6tvre9HQhBl41+OUWu2eEkKoRbC5MpgscESwftvoV5KXpWTpPn8aANzFBLYbHelQEM+2E42agA1/a2DnlECdoW6Pg6QHqAIsUTyYdCvsIQkkSHByWvtg5FtAl7yYJmitfXoJW8JI007HgcII6D1sJQkCzGnf6lcLJxV/JD0KbcO38GPDNDkNTKge02dcyFxxD/bFyVXAH9gYI5k9fZyH4/20Hn0bsoWEzGX2wlxTav8Cj4PmTo7vM+wUo6D7CCVci+g84tpCRxQLQGmWce+YWTMHzTfHhxce4Y+kTo1+bxxRCWKsxN04UQli7MTduFECY8DpDegQQAxlfPidMJISnMaUuAWRMUkzj98LBXv64IFtL2wEGcGBx/cfa0uoeIqvD/QVNQnx5cQyxDXG1W7w8GisqIMmYuPWiC5hrr25kAvZaiqWSUnX/Y0TiObhleeGCn9t/v3z9cD6c2r+FgWfAxaF0Ey6bw5EzZdgLFwxBXk6Wj11ZUOexViYOvQ9ReDWm7v4a4lXL5k9xsU5xeKo/cvxMeNXLXYcZwslEK5NCighk6FY7HhOTVGNfbvdDyL6TXmYt7pBqKAAAAAElFTkSuQmCC',
      treeData: this.treeDataDepPeo,
      cacheListL: this.treeDataDepPeo,
      list: [],
      perId: null,
      basePath: process.env.VUE_APP_API_BASE_URL
    }
  },
  watch: {
    menuListData: {
      handler: function(val) {
        const arr = []
        for (let i = 0; i < val.length; i++) {
          arr.push(val[i].id)
        }
        this.list = arr
      },
      immediate: true
    },
    perDataList(val) {
      if (val) {
        this.cacheListL = perfectTree(this.cacheListL, val, this.perId)
      }
      this.treeData = getChildren(this.cacheListL, this.perId)
    },
    initialTree: {
      handler: function(val) {
        if (val === '1') {
          this.treeData = this.cacheListL
        }
      },
      immediate: true
    }
  },
  methods: {
    // 多选框
    onChange(e) {
      this.treeData = getTree(this.treeData, e.target.checked, e.target.value)
      this.$emit('getMenuTreeData', getIdData(this.cacheListL, e.target.value))
    },
    // 部门点击
    titleClick(item) {
      if (!item.disable || this.list.indexOf(item.id) === -1) {
        this.getBreadcurmbData(item)
        if (item.flag) {
          this.treeData = getChildren(this.cacheListL, item.id)
        } else {
          this.treeData = getTreeChil(this.cacheListL, item.id)
          this.perId = item.id
          this.getDataSource(item)
        }
      }
    },
    // 添加面包屑
    getBreadcurmbData(data) {
      this.$emit('getBreadcurmbData', data)
    },
    // 根据面包屑点击展示数据
    getBreadcurmb(data) {
      if (data.id === '0') {
        this.treeData = this.cacheListL
      } else {
        this.treeData = data.children
      }
    },
    // 调接口获取人员
    getDataSource(item) {
      this.$emit('getDataSource', item)
    }
  }
}
</script>

<style lang="less" scoped>
  .check_box {
    margin: 11px 0;
    color: @sc-grey-100;
    font-size: 14px;
    .cursor {
      color: @sc-grey-100;
      font-size: 14px;
      cursor: pointer;
    }
    .icon {
      width: 26px;
      height: 20px;
      margin: 0 10px 0 20px;
    }
    .title {
      display: inline-block;
      margin-right: 8px;
      width: 140px;
      overflow: hidden;  /*超出部分隐藏*/
      white-space: nowrap;  /*禁止换行*/
      text-overflow: ellipsis; /*省略号*/
    }
    .peo_box {
      display: flex;
      align-items: center;
    }
    .peo_per{
      color: @sc-grey-100;
      font-size: 14px;
      display: flex;
      align-items: center;
      /deep/ .box{
        width: 190px;
        margin: 0 10px 0 12px;
        .user-name{
          margin-left: 8px;
        }
      }
      .jobNumber{
        color: @sc-grey-60;
      }
    }
  }
  .perNoData_div {
    position: relative;
    .perNoData {
      margin-top: 200px;
    }
  }
  .margin_bottom {
    margin-bottom: 26px;
    display: flex;
    justify-items: center;
  }
  .disabled_color {
    color: #8F93A1;
  }
</style>
