<template>
  <div id="box1" class="box">
    <a-tooltip placement="top">
      <template v-if="boxWidth - Number(breadcurmbEndWidth) < Number(breadcurmbWidth)" slot="title">
        <span v-for="(item, index) in breadcurmb" :key="item.id" @click="getBreadcurmb(item)">
          <span v-if="index !== 0"> > </span>
          <span class="tooltip">{{ item.title }}</span>
        </span>
      </template>
      <a-breadcrumb separator=">" class="ellipsis">
        <a-breadcrumb-item v-for="item in breadcurmb" :key="item.id" href="">
          <span @click="getBreadcurmb(item)">{{ item.title }}</span>
        </a-breadcrumb-item>
      </a-breadcrumb>
    </a-tooltip>
    <a-breadcrumb v-if="JSON.stringify(breadcurmbEnd) !== '{}'">
      <a-breadcrumb-item href="" :style="{width: breadcurmbEndWidth + 'px'}" class="end">
        <span class="current_item" @click="getBreadcurmb('end')"><span class="endIcon"> > </span> {{ breadcurmbEnd.title }}</span>
      </a-breadcrumb-item>
    </a-breadcrumb>
  </div>
</template>

<script>
import { getCalculatiowWidth } from './index.js'
export default {
  name: 'Breadcrumb',
  props: {
    corName: {
      type: String,
      default: ''
    },
    breadcurmbList: {
      type: Array,
      default: () => {}
    }
  },
  data() {
    return {
      breadcurmb: [],
      breadcurmbEnd: {},
      breadcurmbWidth: null,
      breadcurmbEndWidth: null,
      boxWidth: null
    }
  },
  watch: {
    breadcurmbList: {
      handler: function(val) {
        this.boxWidth = document.getElementById('box1') ? document.getElementById('box1').offsetWidth : null
        const arr = []
        const breadcurmbEndData = val[val.length - 1] ? val[val.length - 1] : {}
        const breadcurmbData = val.filter(it => it.id !== (val[val.length - 1].id)) ? val.filter(it => it.id !== (val[val.length - 1].id)) : []
        this.breadcurmbEnd = breadcurmbEndData
        this.breadcurmbEndWidth = JSON.stringify(breadcurmbEndData) !== '{}' ? 20 + (breadcurmbEndData.title.length * 14) : 0 // 计算最后一个宽度
        this.breadcurmbWidth = (breadcurmbData ? this.corName.length + getCalculatiowWidth(breadcurmbData) : 0) // 计算数组宽度
        arr.push({ id: '0', title: this.corName })
        arr.push(...breadcurmbData)
        this.breadcurmb = arr
      },
      immediate: true
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.boxWidth = document.getElementById('box1').offsetWidth
    })
  },
  methods: {
    getBreadcurmb(item) {
      if (item === 'end') {
        this.$emit('getBreadcurmb', this.breadcurmbEnd)
      } else {
        this.$emit('getBreadcurmb', item)
      }
    }
  }
}
</script>

<style lang="less" scoped>
   .box {
     display: flex;
     flex-direction: row;
     width: 380px;
   }
  .ellipsis {
    white-space:nowrap;
    overflow:hidden;
    text-overflow:ellipsis;
  }
  .end {
    display: inline-block
  }
  .endIcon{
    color: rgb(186, 186, 186);
    margin-left: 5px;
  }
  .tooltip {
    cursor: pointer;
  }
  .tooltip:hover {
    color: #507FF7;
  }
  .current_item {
    color: @sc-primary-80;
  }
  /*/deep/ .ant-tooltip-inner {*/
  /*  color: #000000 !important;*/
  /*  background: #FFFFFF !important;*/
  /*}*/
</style>
