// 禁用
export function getTree(treeData, disable, id) {
  treeData.forEach(item => {
    if (item.id === id) {
      if (disable) {
        item['disable'] = true
      } else {
        item['disable'] = false
      }
    } else {
      if (item.children && item.children.length > 0) {
        getTree(item.children, disable, id)
      }
    }
  })
  return treeData
}

// 判断是否拉过数据
export function getTreeChil(treeData, id) {
  treeData.forEach(item => {
    if (item.id === id) {
      item['flag'] = true
    } else {
      if (item.children && item.children.length > 0) {
        getTreeChil(item.children, id)
      }
    }
  })
  return treeData
}

// 寻找子级
export function getChildren(dataList, id) {
  let listData = []
  function findData(targetData) {
    for (let i = 0; i < targetData.length; i++) {
      if (targetData[i].id === id) {
        listData = targetData[i].children
        break
      } else {
        if (targetData[i].children && targetData[i].children.length > 0) {
          findData(targetData[i].children, id)
        }
      }
    }
  }
  findData(dataList)
  return listData
}

// 寻找数据
export function getIdData(dataList, id) {
  let list = []
  function find(targetData) {
    for (let i = 0; i < targetData.length; i++) {
      if (targetData[i].id === id) {
        list = targetData[i]
        break
      } else {
        if (targetData[i].children && targetData[i].children.length > 0) {
          find(targetData[i].children, id)
        }
      }
    }
  }
  find(dataList)
  return list
}

// 完善树
export function perfectTree(dataList, newVal, id) {
  dataList.forEach(item => {
    if (item.id === id && item.children) {
      item.children.push(...newVal)
    } else {
      if (item.children && item.children.length > 0) {
        perfectTree(item.children, newVal, id)
      }
    }
  })
  return dataList
}
